import { Controller } from "@hotwired/stimulus";
import anime from "animejs";
export default class extends Controller {
  connect() {
    anime({
      targets: '.united--notify-component',
      translateY: -25,
    });
    
    setTimeout(() => {
      this.element.remove();
    }, 5000)
  }

  toggleNotify(){
    this.element.classList.toggle('united--notify-component-active');
  }
}
