import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  connect() {
  }

  toggleRememberMe(e){
    e.currentTarget.classList.toggle('check-box-active');
    const originalCheckBox = document.getElementById('rememeber_me');
    originalCheckBox.checked = !originalCheckBox.checked;
  }
}
