import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from 'stimulus-use';

export default class extends Controller {
  connect() {
    useClickOutside(this);
  }

  toggleDropdown(){
    this.element.classList.toggle('unlogged--bar-top-dropdown-component-active')
  }

  closeDropdown(){
    this._closeDropdown();
  }

  //private
    _closeDropdown(){
      if (this.element.classList.contains('unlogged--bar-top-dropdown-component-active')) {
        this.element.classList.remove('unlogged--bar-top-dropdown-component-active');
      }
    }
}