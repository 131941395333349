import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  connect() {}

  validateForm(e) {
    const emailEl = document.getElementsByClassName("input-email")[0];
    const firstNameEl = document.getElementsByClassName("input-firstname")[0];
    const lastNameEl = document.getElementsByClassName("input-lastname")[0];
    const passwordEl = document.getElementsByClassName("input-password")[0];
    const repeatPasswordEl = document.getElementsByClassName(
      "input-password-repeat",
    )[0];
    const submitButton = document.getElementsByClassName("btn-submit")[0];
    if (
      firstNameEl.value.length > 0 &&
      lastNameEl.value.length > 0 &&
      passwordEl.value.length > 0 &&
      repeatPasswordEl.value.length > 0 &&
      emailEl.value.length > 0 &&
      window.isRecaptchaSubmited === true
    ) {
      submitButton.disabled = false;
      window.isValidationSuccess = true;
    } else {
      submitButton.disabled = true;
      window.isValidationSuccess = true;
    }

    this._validateFirstName(firstNameEl);
    this._validateLastName(lastNameEl);
    this._validatePassword(passwordEl);
    this._validatePassword(repeatPasswordEl);
    this._validateEmail(emailEl);
  }

  _validateEmail(emailEl) {
    if (emailEl.value.length > 0) {
      emailEl.classList.remove("txt-invalid");
    } else {
      emailEl.classList.add("txt-invalid");
    }
  }

  _validateFirstName(firstNameEl) {
    if (firstNameEl.value.length > 0) {
      firstNameEl.classList.remove("txt-invalid");
    } else {
      firstNameEl.classList.add("txt-invalid");
    }
  }

  _validateLastName(lastNameEl) {
    if (lastNameEl.value.length > 0) {
      lastNameEl.classList.remove("txt-invalid");
    } else {
      lastNameEl.classList.add("txt-invalid");
    }
  }

  _validatePassword(passwordEl) {
    if (passwordEl.value.length > 0) {
      passwordEl.classList.remove("txt-invalid");
    } else {
      passwordEl.classList.add("txt-invalid");
    }
  }

  _validateEmail(emailEl) {
    if (emailEl.value.length > 0) {
      emailEl.classList.remove("txt-invalid");
    } else {
      emailEl.classList.add("txt-invalid");
    }
  }
}
