// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "../../channels";
import "../../components/unlogged";

window.recaptchaCallback = function () {
  window.isRecaptchaSubmited = true;

  if (
    window.isRecaptchaSubmited === true &&
    window.isValidationSuccess === true
  ) {
    const submitButton = document.getElementsByClassName("btn-submit")[0];
    submitButton.disabled = false;
  }
};
