import { application } from "./application"

import SessionsNewComponentController from "../../../components/ui/unlogged_cn/sessions_new_component/sessions_new_component_controller"
application.register("unlogged--sessions-new-component", SessionsNewComponentController)

import RegistrationsNewComponentController from "../../../components/ui/unlogged_cn/registrations_new_component/registrations_new_component_controller"
application.register("unlogged--registrations-new-component", RegistrationsNewComponentController)

import EmailConfirmedComponentController from "../../../components/ui/unlogged_cn/email_confirmed_component/email_confirmed_component_controller"
application.register("unlogged--email-confirmed-component", EmailConfirmedComponentController)

import NotifyComponentController from "../../../components/ui/united_cn/notify_component/notify_component_controller"
application.register("united--notify-component", NotifyComponentController)

import BarTopDropdownComponentController from "../../../components/ui/unlogged_cn/bar_top_dropdown_component/bar_top_dropdown_component_controller"
application.register("unlogged--bar-top-dropdown-component", BarTopDropdownComponentController)